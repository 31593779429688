import logo from './logo.svg';
import DKMGIF from './assets/DKM_2122_logo_animated_small.gif'
import {useState} from 'react'
import './App.css';
import Countdown from 'react-countdown';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Image from './Image';
import CountdownPage from './CountdownPage';

function App() {
  let date = new Date();


  return (
    <Router>
      <Routes>
      <Route exact path="/about" element={<Image/>}/>
      <Route path="/" element={<CountdownPage/>}/>
      </Routes>
    </Router>
  );
}

export default App;
