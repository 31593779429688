import React from 'react';

const Image = () => {
    return (
        <div>
            <img width="100%" src="https://s3.eu-central-1.amazonaws.com/dkm.io/about/Vad+go%CC%88r+DKM_-min.png" alt="hej"/> 
        </div>
    );
};

export default Image;