import logo from './logo.svg';
import DKMGIF from './assets/dkm-new-logo-gif.gif'
import {useState} from 'react'
import './App.css';
import Countdown from 'react-countdown';

let finalDate = new Date(2022, 9, 12, 23, 59, 59, 1000)
function CountdownPage() {
    let date = new Date();

    const [complete, setComplete] = useState(finalDate < new Date());
    const completeFunc = () => {
      setComplete(true);
    }

  return (
    <div className={`app ${complete ? 'app-finished' : ''}`}>
      {!complete ? <h2 id="title">SÖK DKM 22/23</h2> : <h2 id="closed-title">DKM 22/23</h2>}
      {!complete && <p id="ansok"> -&#62; dkm.io/ansok &#60;- </p>}
      <div> 
        {/* <img id="dkm-gif" src={DKMGIF} alt="gif"/> */}
      </div>
      {!complete && <p id="countdown-desc">sök nu innan ansökningen stänger!!</p>}
            <div id="countdown">
        {!complete ? <Countdown date={finalDate} onComplete={completeFunc} /> : <span className="closed">Ansökan är stängd!</span>}

      </div>
    </div>
  );
}

export default CountdownPage;